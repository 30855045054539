// Size
$headerHeight: 4.6875rem;
$sidebarWidth: 24.375rem;
$connectionNotificationHeight: 2.5rem;
$headerWidthWithoutTimeline: 695px;
$timelineScrollRightWidth: 45px;

// Z-index
$miniViewZIndex: 9998;
$miniViewCapturedAreaZIndex: 9998;
$popoverZIndex: 9999;
