@mixin notSelected {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin scrollSidebar {
  width: 100%;
  height: calc(100% - 2.8125rem - 3.5625rem);
  overflow-y: scroll;
}

@mixin scrollSidebarWithoutBtn {
  @include scrollSidebar();

  height: calc(100% - 3.5625rem);
}

@mixin withScrollBar {
  &::-webkit-scrollbar {
    width: 0.3125rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--soft-grey);
    border-radius: 0.3125rem;
  }

  &::-webkit-scrollbar-track {
    background: var(--contain-bg);
  }
}

@mixin withScrollBarForFirefox {
  scrollbar-width: thin;
  scrollbar-color: var(--soft-grey) var(--contain-bg);

  &:hover {
    scrollbar-width: thin;
    scrollbar-color: var(--soft-grey) var(--contain-bg);
  }
}

@mixin withoutScrollBar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin viewerItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin viewerHide {
  opacity: 0;
  visibility: hidden;
}

@mixin notSelected {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@mixin activeControl {
  color: var(--white);
  background: var(--outflier-blue);
  border-color: rgba(253, 253, 253, 0.25);

  &:hover {
    background: var(--outflier-blue);
  }
}

@mixin centerElement {
  display: flex;
  align-items: center;
  justify-content: center;
}
