:root {
  --outflier-black: #393939;
  --outflier-dark-graylish-blue: #474c5b;
  --outflier-blue: #0677ff;
  --outflier-blue-light: #2797ff;
  --outflier-dark-blue: #2E50B5;
  --outflier-blue-cyan:#7cb6ec;
  --outflier-blue-cyan-grey-shade: #6f91b7;
  --outflier-blue-grey: #8f9bc1;
  --outflier-blue-grey-shade: #7c879a;
  --outflier-blue-sky:#7c80ec;
  --outflier-blue-sky-grey-shade: #6e72b4;
  --outflier-flight-path-blue: #1f1f1f;
  --outflier-blue-hover: #0054ba;
  --outflier-yellow: #ffbc2d;
  --outflier-yellow-light-shade: #fff06b;
  --outflier-yellow-light-grey-shade: #ccc38a;
  --outflier-green: #00c54f;
  --outflier-yellow-grey: #ac893b;
  --outflier-white: #fffdf8;
  --outflier-dark-red: #E43535;
  --outflier-red-light-shade: #ff6955;
  --outflier-red-light-grey-shade: #c36969;
  --transparent: rgba(255, 255, 255, 0);
  --white: #fdfdfd;
  --transparent-black: rgba(0, 0, 0, 0.5);
  --white-grey: #979797;
  --light-grey: #d9d9d9;
  --red: #f64034;
  --red-grey: #9d413c;
  --red-orange-dark: #fe5038;
  --red-orange-light: #f77046;
  --red-orange-light-shade: #f98645;
  --orange-light: #fbA251;
  --green: #00c54f;
  --vinous: rgba(228, 53, 53, 0.15);
  --green-grey: #2d8558;
  --main-bg: #161825;
  --contain-bg: #242b41;
  --contain-bg-hover: #3b476b;
  --alternate-bg: #242e40;
  --alternate2-bg: #1f2239;
  --soft-grey: #9499a5;
  --soft-black: #2b2f42;
  --hard-black: #00000f;
  --hard-black-hover: #2b2b43;
  --outflier-color1: #3f3f4a;
  --outflier-color2: #21204c;
  --outflier-color2-rgb: rgb(33, 32, 76);
  --outflier-color3: #3a4054;
  --outflier-color4: #bec8d4;
  --outflier-color5: #686c83;
  --outflier-color6: #1d2233;
  --outflier-color7: #2d2f3b;
  --outflier-color8: #e1dcc3;
  --outflier-color9: #373e38;
  --outflier-color10: #1a1d2d;
  --light-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  --tooltip-box-shadow: 0px 0px 1px 0px #fff;
}
