.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  gap: 0.125rem;
}

.head {
  color: var(--soft-grey);
  font-family: Inter, sans-serif;
  font-size: 0.75rem;
  line-height: 2.125rem;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
}

.row {
  display: flex;
  width: 100%;
  background: var(--contain-bg);
  padding: 0.4375rem 0.9375rem;
  margin-bottom: 0;
  gap: 0.125rem;
}

.cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.125rem;
  min-height: 20px;
  font-size: 0.75rem;
}

.headCell {
  color: var(--soft-grey);
  user-select: none;
  padding: 0;
}

.bodyCell {
  overflow: hidden;
}
