@import '@styles/mixins.scss';

.zone {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mediaInspections {
  margin: 1.25rem 0;
}

.zoneWrapper {
  @include withoutScrollBar();
  @include scrollSidebarWithoutBtn();
}

.anomaliesStatuses {
  margin-bottom: 0.3125rem;
}

.anomaliesStatusesTitle {
  margin-bottom: 0.625rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.anomaliesTableHead,
.anomaliesTableBody {
  padding-left: 0.625rem;
}

.anomaliesTableBodyRow {
  font-weight: 500;
  font-size: 0.875rem;
}

.anomaliesTableBodyAnomalyRow {
  font-weight: 600;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.9375rem 0 1.25rem;
  font-weight: 500;
  font-size: 1rem;
  margin-top: 0.3125rem;

  img {
    width: 1.875rem;
  }

  &:disabled:hover {
    opacity: 0.7;
  }
}

.statistic {
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
}

.anomaliesTitle {
  color: var(--white);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 2.125rem;
}

