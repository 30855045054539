.menu {
  min-width: 9.375rem;
  position: absolute;
  top: 2.5rem;
  right: 0;
  display: flex;
  flex-direction: column;
  border-radius: 0.3125rem;
  overflow: hidden;
  perspective: 0.0625rem;
  border: 0.125rem solid var(--outflier-black);
  background-color: var(--contain-bg);
}

.menuItem {
  padding: 10px;
  color: var(--white);
  text-align: left;
  font-size: 0.875rem;
  font-weight: 600;
  transition: all 0.3s;
  z-index: 3; // To be above modal disabled background

  &[aria-disabled="true"]  {
    cursor: not-allowed;
  }

  &:hover {
    background-color: var(--contain-bg-hover);
  }
}
