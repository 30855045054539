@import '@styles/mixins';

.root {
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--white);
  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 2.125rem;
}

.tilesList {
  display: flex;
  flex-direction: column;
  gap: 0.1875rem;
}

.tilesRow {
  display: flex;
  gap: 0.125rem;
}

.tileItem {
  @include centerElement;

  height: 45px;
  flex: 1;
  border-radius: 0.1875rem;
  background: var(--contain-bg);
}

.tileBtn {
  @include centerElement;

  appearance: none;
  gap: 0.625rem;
  width: 100%;
  height: 100%;
  cursor: unset;
}

.tileTitle {
  color: var(--white);
  text-align: center;
  font-family: Archivo, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.125rem;
}

.isActive {
  & span {
    color: var(--hard-black);
  }

  & .icon {
    svg {
      & rect {
        stroke: var(--hard-black);
      }
      & path {
        stroke: var(--hard-black);
      }
    }
  }
}
