@import '@styles/mixins';

.site {
  width: 100%;
  height: 100%;
}

.siteWrapper {
  @include withoutScrollBar();
  @include scrollSidebar();

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.logo {
  background-color: gray;
  border-radius: 3px;
  width: 350px;
  height: 200px;
  min-height: 200px;
  overflow: clip;
}

.siteImage {
  width: 350px;
  height: 200px;
  object-fit: cover;
}

.siteZones,
.siteAnomalies {
  margin-bottom: 0.3125rem;
}

.siteTableTitle {
  margin-bottom: 0.625rem;
}

.inspected {
  color: var(--soft-grey);
}

.buttonsContainer {
  display: flex;
  gap: 5px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.9375rem 0 1.25rem;
  font-weight: 500;
  font-size: 1rem;
  margin-top: 0.3125rem;
}

.statistic {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
