$calendarElementsGap: 2px;
$weekInDays: 7;

.dialogContainer {
  position: fixed;
  inset: 0px;
  z-index: 10;
  overflow-y: auto;
}

.dialog {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: fixed;
  inset: 0px;
  background-color: var(--transparent-black);
}

.panel {
  position: relative;
  width: 56%;
  height: 68%;
  background-color: var(--contain-bg);
}

.panelHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.9375rem;
  min-height: 55px;
}

.panelTitle {
  font-family: 'Archivo', sans-serif;
  font-size: 1.1875rem;
  font-weight: 500;
  line-height: 2rem;
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  outline: none;

  & svg {
    width: 35px;
    height: 35px;
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.625rem 0.9375rem 0 0.9375rem;
  background-color: var(--main-bg);
}

.requestButtonContainer {
  display: flex;
  justify-content: center;
  padding: 1.875rem 2rem;
}

.requestButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.9375rem 0 1.25rem;
  width: unset;
  min-width: 234px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 2.125rem;
}

.calendarContainer {
  background-color: var(--contain-bg);
}

.calendarTile {
  background-color: var(--contain-bg);

  &:enabled:hover {
    color: var(--white);
    background-color: var(--outflier-blue);
  }
}

.calendarPastTile {
  color: var(--soft-grey);
  background-color: var(--outflier-color10);
}

.calendarNotCurrentMonthsTile {
  color: var(--contain-bg);
  background-color: var(--outflier-color10);

  &:enabled:hover {
    color: var(--white);
  }
}

.calendarNotAvailableTile {
  background-color: var(--vinous);
}

.weatherIcon {
  max-width: 30px;
  max-height: 30px;
  margin-bottom: 0.625rem;

  & svg {
    width: 35px;
    height: 35px;
  }
}

.calendarContainer {
  :global {
    .react-calendar {
      max-width: 100%;
      font-family: 'Archivo', sans-serif;
      background-color: var(--main-bg);
    }

    abbr {
      text-decoration: none;
    }

    .react-calendar,
    .react-calendar *,
    .react-calendar *:before,
    .react-calendar *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    .react-calendar button {
      margin: 0;
      outline: none;
    }

    .react-calendar button:enabled:hover {
      cursor: pointer;
    }

    .react-calendar__navigation {
      display: flex;
      padding: 0.625rem 0.9375rem 0.625rem 1.5625rem;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.75rem;
      background-color: var(--main-bg);
    }

    .react-calendar__navigation button {
      min-width: 35px;
      height: 40px;
    }

    .react-calendar__navigation button:disabled {
      cursor: pointer;
    }

    .react-calendar__month-view__days {
      display: flex;
      gap: $calendarElementsGap;
      background-color: var(--main-bg);
    }

    .react-calendar__month-view__weekdays {
      display: flex;
      margin-bottom: $calendarElementsGap;
      text-align: center;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.125rem;
      gap: $calendarElementsGap;
      background-color: var(--main-bg);
    }

    .react-calendar__month-view__weekdays__weekday {
      flex: 0 0 calc(100% / $weekInDays - $calendarElementsGap) !important;
      padding: 0.5rem;
      height: 40px;
      background: var(--alternate2-bg);
      box-shadow: var(--light-box-shadow);
      border-radius: 3px;
    }

    .react-calendar__tile {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      padding: 1.0625rem 0.625rem 0.625rem 0.625rem;
      max-width: 100%;
      min-height: 80px;
      text-align: center;
      font-size: 1rem;
      font-weight: 500;
      box-shadow: var(--light-box-shadow);
      border-radius: 3px;
      flex-basis: calc(100% / $weekInDays - $calendarElementsGap) !important;
    }

    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: var(--outflier-blue);
    }

    .react-calendar__tile--now {
      color: var(--hard-black);
      background: var(--white);

      svg path {
        fill: var(--hard-black);
        stroke: var(--hard-black);
      }
    }

    .react-calendar__tile--active {
      color: var(--white);
      background: var(--outflier-blue);
    }

    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
      color: var(--white);
      background: var(--outflier-blue-hover);
    }

    .react-calendar--selectRange .react-calendar__tile--hover {
      background-color: var(--light-grey);
    }
  }
}
