.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.1875rem;

  // Create a triangle
  &::after {
    content: "";
    position: absolute;
    width:0;
    height:0;
    top: 50%;
    right: 0%;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid var(--main-bg);
    transform: translate(100%, -50%);
  }
}

.header {
  display: flex;
  padding: 0.625rem 0.625rem 0.625rem 1.25rem;
  align-items: center;
  gap: 1.25rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--contain-bg);
  background: var(--contain-bg);
}

.title {
  color: var(--white);
  font-family: Archivo, sans-serif;
  font-size: 1.1875rem;
  font-weight: 600;
  line-height: 2rem;
}

.closeBtn {
  cursor: pointer;
}

.closeIcon {
  & svg {
    width: 35px;
    height: 35px;
  }
}

.content {
  width: 100%;
  padding: 0 0.625rem 0 1.25rem;
  display: flex;
  align-items: flex-start;
  gap: 0.3125rem;
  flex-shrink: 0;
  background: var(--main-bg);
  border-bottom-left-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}
