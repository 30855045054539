.root {
  display: flex;
  gap: 0.125rem;
  align-items: center;
  height: 172px;
  margin: 0.625rem auto;
}

.chart {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  padding: 1.875rem 0.625rem;
  border-radius: 0.1875rem;
  background: var(--contain-bg);
}

.tilesList {
  display: grid;
  height: 100%;
  width: 100%;
  flex: 1;
  gap: 0.125rem;
}

.tilesRow {
  display: flex;
  gap: 0.125rem;
}
