$controlsHeight: 6.25rem;

.imagesWrapper {
  flex: 1;
  overflow: hidden;
}

.controls {
  position: relative;
  width: 100%;
  height: $controlsHeight;
  background: var(--main-bg);
  padding: 1.25rem;
  display: flex;
  align-items: center;
}

.controlsLeft {
  display: flex;
  align-items: center;
  flex: 50%;
  column-gap: 0.625rem;
}

.controlsCenter {
  flex: 50%;
}
