.segmentationMask,
.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.thermalImage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, red, blue);
    mix-blend-mode: color;
  }
}

.image, .segmentationMask, .thermalImage {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.imageLoaded {
  opacity: 1;
}

.segmentationMaskLoaded {
  opacity: 0.8;
}
