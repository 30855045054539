.root {
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 0.625rem;
}

.counter {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.title {
  color: var(--white);
  font-family: Inter, sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.6875rem;
}

.counterValue {
  font-family: Archivo, sans-serif;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 2rem;
}

.active {
  color: var(--outflier-blue);
}

.defective {
  color: var(--red);
}
