.tooltipContainer {
  top: 10px;
  padding: 7px 15px;
  font-size: 13px;
  color: var(--outflier-soft-gray);
  border-radius: 4px;
  opacity: 0.95;
  z-index: 4; // To be above modal disabled background
}

.tooltipTrigger {
  width: 25px;
  height: 14px;
  margin-top: 28px;
}

.tooltipArrow {
  top: -10px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
