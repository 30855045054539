.root {
  display: flex;
  align-items: center;
  padding: 0.625rem 1.25rem;
  border-radius: 0.1875;
  background: var(--contain-bg);
}

.label {
  display: flex;
  align-items: center;
  flex: 1;
}

.labelTitle {
  color: var(--white);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.6875rem;
}

.dirtinessIndex {
  width: 130px;
  height: 6px;
  border-radius: 0.1875rem;
  background-color: var(--outflier-black);
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    left: 60%;
    width: 2px;
    height: 10px;
    opacity: 0.9;
    background-color: var(--red);
  }
}

.dirtinessIndexValue {
  color: var(--white);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.6875rem;
}

.progressBar {
  height: 100%;
  border-bottom-left-radius: 0.1875rem;
  border-top-left-radius: 0.1875rem;
}
