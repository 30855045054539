.tooltip {
  position: absolute;
  padding: 0.5rem;
  color: var('--white');
  border-radius: 0.25rem;
  pointer-events: none;
  font-size: 0.8125rem;
  background: var(--contain-bg);
}

.group {
  font-size: 0.875rem;
}

.subgroup {
  font-size: 0.75rem;
  font-weight: 500;
}

.value {
  font-size: 0.75rem;
}
