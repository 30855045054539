.root {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
}

.title {
  margin-bottom: 10px;
}

.container {
  display: flex;
  background: var(--contain-bg);
  border-radius: 0.1875rem;
}

.chart {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  padding: 1.875rem 0.625rem;
}

.tilesList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex: 1;

  & > .tile {
    border-radius: 0.1875rem;
  }
}

.tilesRow {
  display: flex;
  gap: 0.125rem;
}
