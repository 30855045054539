@import '@styles/variables';

.miniView {
  z-index: $miniViewCapturedAreaZIndex;
  position: absolute;
  display: flex;
  width: 305px;
  height: 210px;
  left: 21px;
  top: 23px;
  border-top: 0.25rem solid var(--main-bg);
  border-right: 0.25rem solid var(--main-bg);
  border-bottom: 0.25rem solid var(--main-bg);
  overflow: hidden;
}

.controls {
  flex-basis: 45px;
  display: flex;
  flex-direction: column;
  gap: 0.0938rem;
  height: 100%;
  background:  var(--main-bg);
}

.control {
  flex: 1;
  background-color: var(--contain-bg);
  border-radius: 0;
  border: none;
}

.images {
  flex: 1;
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--main-bg);
  overflow: hidden;
}

.capturedAreaFrame {
  position: absolute;
  z-index: $miniViewCapturedAreaZIndex;
  border: 0.125rem solid  var(--outflier-blue);
  opacity: 0.6;
}
