@import '@styles/mixins.scss';

.root {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: var(--main-bg);
}

.container {
  display: flex;
  width: 390px;
  padding: 20px 15px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.title {
  margin: 0;
  color: var(--white);
  text-align: center;
  font-size: 1.375rem;
  line-height: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.subtitle {
  color: var(--white);
  text-align: center;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.2px;
}

.main {
  width: 100%;
  padding: 27px 0;
}

.list {
  @include withScrollBar();
  @include withScrollBarForFirefox();

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 151px;
  overflow: hidden;
  overflow-y: auto;
}

.tenantItem {
  & > button {
    text-align: center;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 2.125rem;
  }
}

.footer {
  width: 100%;
}

.logoutBtn {
  color: var(--white);
  background-color: var(--red);
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 2.125rem;
  width: 100%;
}
