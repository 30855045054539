@import '@styles/variables';

.title {
  color: var(--white);
  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 2.125rem;
}


.container {
  display: flex;
  flex-direction: column;
  gap: 0.0625rem;
}

.panelsTile {
  display: flex;
  gap: 1.25rem;
  padding: 0.625rem 1.25rem;
  border-radius: 0.1875rem;
  background: var(--contain-bg);
}

.popover {
  width: 376px;
  position: absolute;
  z-index: $popoverZIndex;
  transform: translateX(calc(-100% - 1.875rem));
}

