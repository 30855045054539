@import '@styles/variables';

.popover {
  width: 340px;
  position: absolute;
  z-index: $popoverZIndex;

  & section {
    padding: 0;
  }
}

.list {
  display: flex;
  flex-direction: column;
  padding: 0.625rem 0;
  width: 100%;
  padding: 0.625rem 1.25rem;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  cursor: pointer;
}

.radioGroup {
  width: 100%;
}

.checkboxButton {
  width: 12px;
  height: 12px;
  border-radius: 0.0625rem;
  border: 0.125rem solid var(--white);
}

.isCheckedOption {
  background: var(--outflier-blue);
}
