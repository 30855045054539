.root {
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--white);
  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 2.125rem;
}

.tiles {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.row {
  display: flex;
  height: 90px;
  gap: 1px;
}


.surfaceAffectedPopover,
.thermalSurfaceAffectedPopover,
.panelsAffectedPopover,
.subtypePopover {
  transform: translateX(calc(-100% - 2.5rem));
}

.temperatureDifferencePopover,
.visualSurfaceAffectedPopover {
  transform: translateX(calc(-100% - 13.5rem));
}

.surfaceAffectedValue,
.panelsAffectedValue,
.thermalSurfaceAffectedValue {
  color: var(--red);
}

.thermalSurfaceAffectedTileValue span {
  color: var(--red);
}

.visualSurfaceAffectedTileValue span {
  color: var(--outflier-blue-cyan);
}

.visualSurfaceAffectedValue {
  color: var(--outflier-blue-cyan);
}

.temperatureDifferenceValue {
  color: var(--outflier-yellow);
}
