.shield {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  padding: 2px 8px;
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  font-family:  Inter, sans-serif;
  font-feature-settings:
    'clig' off,
    'liga' off;
  border-radius: 6px;
}

.tooltipContainer {
  top: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  padding: 20px 30px;
  min-width: 440px;
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  font-feature-settings:
    'clig' off,
    'liga' off;
  background-color: var(--main-bg);
  border-radius: 3px;
  box-shadow: var(--tooltip-box-shadow);
}

.tooltipArrow {
  top: -10px;
  width: 20.5px;
  height: 18px;
}
