@import '@styles/mixins';
@import '@styles/variables';

.popover {
  width: 340px;
  position: absolute;
  z-index: $popoverZIndex;

  & section {
    padding: 0;
  }
}

.container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.25rem 0.625rem;
  gap: 0.625rem;
}

.controllerBtn {
  @include centerElement;

  flex-direction: column;
  width: 35px;
  height: 35px;
  gap: 0.625rem;
  border-radius: 0.1875rem;
  background: var(--contain-bg);
}

.valueWrapper {
  @include centerElement;

  border-radius: 0.1875rem;
  background: var(--contain-bg);
  flex: 1;
}

.value {
  padding: 0.75rem 0;
  text-align: center;
  font-family: Archivo, sans-serif;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 2rem;
}
