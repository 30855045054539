.container {
  padding: 0.9375rem;
  background: var(--contain-bg);
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name {
  display: flex;
  align-items: center;
  column-gap: 0.3125rem;

  h3 {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--outflier-blue);
  }

  span {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--soft-grey);
    text-transform: capitalize;
  }
}

.buttons {
  display: flex;
  align-items: center;
  column-gap: 0.3125rem;

  button {
    color: var(--white);
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 0.1875rem;
    background: var(--alternate2-bg);
    padding: 0.3125rem 0.625rem;
  }
}

.body {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;

  margin-top: 0.3125rem;
  margin-bottom: 0.625rem;
  font-size: 1rem;
  font-weight: 500;
  font-style: italic;
  line-height: 1.25rem;
}

.footer {
  color: var(--soft-grey);
  font-size: 0.875rem;
  font-weight: 500;
}

.textareaWrapper {
  background: var(--contain-bg);
  padding: 0.9375rem;
}
