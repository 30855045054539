.eyeBtn {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.5rem;
  height: 1.5rem;
}

.eyeIcon {
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  path {
    fill: var(--white);
  }
}
