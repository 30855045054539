.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 3px;
  background: var(--contain-bg);
  gap: 0.625rem;
  flex: 1;
}

.label {
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 0.75rem;
  line-height: 0.6875rem;
}

.value {
  font-family: Archivo, sans-serif;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 2rem;
}

.color {
  // "Severity" counters
  &-false {
    color: var(--outflier-blue-grey);
  }

  &-to_review {
    color: var(--outflier-yellow);
  }

  &-minor {
    color: var(--outflier-white);
  }

  &-major {
    color: var(--red);
  }

  // "Type" counters
  &-visual {
    color: var(--outflier-blue-cyan);
  }

  &-mixed {
    color: var(--outflier-blue-sky);
  }

  &-thermal {
    color: var(--outflier-red-light-shade);
  }

  &-string {
    color: var(--outflier-yellow-light-shade);
  }

  // "Repair" counters
  &-delayed {
    color: var(--white);
  }

  &-to_repair {
    color: var(--outflier-dark-red);
  }

  &-repaired {
    color: var(--green);
  }
}
