.anomalyStatisticControls {
  display: flex;
  border-radius: 0.1875rem;
  gap: 0.125rem;

  & > .controlBtn {
    flex: 1;
    color: var(--white);
    text-align: center;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 2.125rem;
    border-radius: unset;
    background: var(--contain-bg);
    padding: 0.375rem 0;
  }

  & > :first-child {
    border-top-left-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem;
  }

  & > :last-child {
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
  }

  & > .isActive {
    background: var(--outflier-blue);
    box-shadow: 0px 0px 6px 0px var(--light-box-shadow);
  }
}
