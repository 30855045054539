.anomalyColumn {
  color: var(--white) !important;
}

.anomalyIdColumn {
  width: 108px;
}

.typeColumn {
  width: 83px;
  text-align: left;
}

.lostYearColumn {
  width: 82px;
  text-align: left;
}

.repairColumn {
  width: 42px;
  text-align: left;
}

.anomalyIdStatus {
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &-false {
    background-color: var(--outflier-blue-grey);
  }
  &-minor {
    background-color: var(--white);
  }
  &-to_review {
    background-color: var(--outflier-yellow);
  }
  &-major {
    background-color: var(--outflier-dark-red);
  }
}

.anomalyIdTableCell {
  display: flex;
  gap: 10px;
  align-items: center;
  overflow: hidden;
}

.anomalyIdLabel {
  font-weight: 600;
}

.lostYearLabel {
  font-weight: 500;
}

.cellLabel {
  color: var(--white);
  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  line-height: 2.125rem;
  overflow: inherit;
  text-overflow: ellipsis;
}

.chevron svg {
  width: 20px;
  height: 20px;

  & > path {
    stroke: var(--white);
  }
}

.sortBtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 20px;
  appearance: none;
  margin-left: 1.25rem;
}

.shieldText {
  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 2.125rem;
  width: 65px;
  height: 25px;
}

