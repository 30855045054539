.statuses {
  display: flex;
  flex-wrap: wrap;
  gap: 0.125rem;
}

.status {
  flex: 1 1 calc(50% - 2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.9375rem 0;
  gap: 0.375rem;
  border-radius: 0.1875rem;

  &:disabled {
    cursor: auto;
  }

  &:disabled:hover {
    opacity: 1;
  }
}

.statusName {
  font-size: 0.75rem;
}

.statusAmount {
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.5625rem;
}

.bigText {
  font-size: 1rem;
  font-weight: 500;
}

.notClicked {
  outline: none;

  &:enabled:hover {
    opacity: 1;
  }
}
