.header,
.subHeader {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header {
  font-weight: 700;
}

.subHeader {
  padding-right: 1rem;
}

.subHeaderContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

button {
  &:disabled {
    cursor: not-allowed;

    &:hover {
      opacity: 1;
    }
  }
}
