.root {
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--white);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 34px; /* 242.857% */
}

.tilesList {
  display: flex;
  background: var(--contain-bg);
  padding: 1.25rem 0;
  border-radius: 0.1875rem;
}

.tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 0.75rem;
}

.tileTitle {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.6875rem;
}

.counter {
  display: flex;
  align-items: flex-end;
  gap: 0.3125rem;
}

.counterValue {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 2rem;
}

.atInspection {
  color: var(--red);
}

.afterRepair {
  color: var(--outflier-yellow);
}

.moneyPerYear {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.estimatedLossesPopover {
  width: 400px;
  position: absolute;
  transform: translateX(calc(-100% - 1.875rem));
}

