@import '@styles/mixins';

.root {
  display: flex;
  flex-direction: column;
}

.tile {
  display: flex;
  flex-direction: column;
  background: var(--contain-bg);
  padding: 1.25rem 0.625rem;
  gap: 0.3125rem;
  flex: 1;
}

.title {
  color: var(--white);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 0.75rem;
  line-height: 0.8125rem;
}

.valueWrapper {
  @include centerElement;
  position: relative;
}

.value {
  flex: 1;
  text-align: center;
  font-weight: 500;
  line-height: 2.125rem;
}

.isEditable {
  margin-left: 1.125rem;
}

.chevronWrapper {
  @include centerElement;

  height: 100%;
  position: absolute;
  right: 0;
  z-index: 2;
}

.chevronIcon {
  width: 18px;
  height: 18px;

  & svg {
    width: 18px;
    height: 18px;

    & > path {
      stroke: var(--white);
      stroke-width: 4;
    }
  }
}
