@import '@styles/variables';

.header {
  position: relative;
  width: 100%;
  height: $headerHeight;
  background-color: #00000f;
  color: white;
  border-bottom: 1.5px solid #393939;
  z-index: 3; // To be above modal disabled background

  display: grid;
  grid-template-columns: 190px 1fr 220px 170px;
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: 1 / 1 / 2 / 2;
  border-right: 1.5px solid #393939;
}

.logoBtn {
  outline: none;
  border: none;
}

.logo {
  width: 144px;
}

.timelineWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: 1 / 2 / 2 / 3;
  border-right: 1.5px solid #393939;
}

.dateDropdownWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: 1 / 3 / 2 / 4;
  border-right: 1.5px solid #393939;
  padding: 0 0.625rem;
}

.userDropdownWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: 1 / 4 / 2 / 5;
}
